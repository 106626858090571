import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import { SEO } from "../components/seo";
import useCookie from "../hooks/use-cookie";
import { getBrowserLanguage } from "../hooks/get-language";
import { getPageByLanguage } from "../hooks/get-page-by-language";
import { getHeaderAndFooter } from "../hooks/get-header-and-footer";
import parse from "html-react-parser";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginBottom: 32,
  fontSize: "40px",
};
const paragraphStyles = {
  marginBottom: 32,
};

const NotFoundPage = ({ data, location }) => {
  const [dropdown, setDropdown] = useState({
    key: "",
    name: "",
  });
  const [cookie, setCookie] = useCookie(
    "language",
    getBrowserLanguage(location)
  );

  const { foundHeader, foundFooter } = getHeaderAndFooter(data, cookie);
  let found404 = getPageByLanguage(
    data.allContentstack404.nodes,
    false,
    cookie
  );

  return (
    <Layout
      navigation={foundHeader}
      footer={foundFooter}
      dropdown={dropdown}
      setDropdown={setDropdown}
      cookie={cookie}
      setCookie={setCookie}
    >
      <main style={pageStyles}>
        <h1 style={headingStyles}>{found404?.heading}</h1>
        <p style={paragraphStyles}>{parse(found404?.text_block)}</p>
        <Link to="/">{found404?.home_link_text}</Link>
      </main>
    </Layout>
  );
};

export const query = graphql`
  {
    allContentstack404 {
      nodes {
        heading
        locale
        text_block
        home_link_text
      }
    }
    allContentstackHeader {
      nodes {
        locale
        menu {
          link {
            href
            title
          }
        }
        logo {
          logo_title
          logo_image {
            url
          }
        }
      }
    }
    allContentstackFooter {
      nodes {
        locale
        address {
          street
          city_state_zip
          phone_number
          email
        }
        company_motto {
          motto_line_1
          motto_line_2
        }
        copyright
        locale
        title
        uid
        secondary_menu {
          link {
            href
            title
          }
        }
        main_links {
          link {
            href
            title
          }
        }
        logo {
          logo_image {
            url
          }
          logo_title
        }
      }
    }
  }
`;

export default NotFoundPage;
export const Head = () => <SEO title="Elevate Outdoor Collective | 404" />;
